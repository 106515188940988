<template>
  <div class="page">
    <Navbar title="修改密码" />
    <van-form @submit="modify" class="form">
      <van-cell-group>
        <van-field
          v-model="oldpwd"
          label="原密码"
          placeholder="请输入原密码"
          :rules="[{ required: true, message: '请输入原密码' }]"
        ></van-field>
        <van-field
          v-model="newpwd"
          center
          clearable
          label="新密码"
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '请输入新密码' }]"
        >
        </van-field>
        <van-field
          v-model="cnewpwd"
          center
          clearable
          label="确认密码"
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '请重复输入新密码' }]"
        >
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR_M"
              icon="success"
              size="small"
              native-type="submit"
            >
              确认修改
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
  </div>
</template>
<script>
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
      oldpwd: '',
      newpwd: '',
      cnewpwd: ''
    }
  },
  methods: {
    async modify () {
      if (this.newpwd !== this.cnewpwd) {
        this.$dialog.alert({
          title: '提示',
          message: '两次输入新密码不相同'
        })
        return false
      }

      var pd = { oldPwd: this.oldpwd, newPwd: this.newpwd }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/updatePassword', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示',
          message: '修改成功'
        }).then(() => {
          this.$router.push({ path: '/seller/own' })
        })
      } else {
        this.$dialog.alert({
          title: '提示',
          message: '修改失败：' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 10px 0px;
  .subbtn {
    margin: 20px auto;
  }
}
</style>
